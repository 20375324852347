import * as React from "react";
import { StaticImage } from "gatsby-plugin-image";
import { Link } from "gatsby";
import Layout from "../../components/layout";
import SEO from "../../components/seo";
import { ProjectSummary } from "../../components/projects/ProjectSummary";

const BrooksideSubdivision = () => (
  <Layout>
    <SEO
      title="Brookside Subdivision"
      image={"/brookside.jpg"}
      description="The general scope of work was to install utility services to the
              proposed Brookfield subdivision."
    />

    <div className="row">
      <div className="col-lg-6">
        <div className="content-box">
          <h1 className="section-heading section-heading--left">
            Brookside Subdivision
          </h1>
          <ProjectSummary
            name="Brookside Subdivision"
            type="Subdivision"
            location="Featherston, Wairarapa"
            client="Smalltime Developments"
            engineer="Tomlinson &amp; Carruthers Surveyors Ltd"
          />
          <p>
            The general scope of work was to install utility services to the
            proposed Brookfield subdivision.
          </p>
          <p>
            Stage 1-2 of the works included providing 16 lots with new services,
            water, sewer, stormwater and power. A total of 9 sewer manholes were
            installed as part of stage 2 with over 200 meters of 150mm sewer
            pipeline connecting them. Ongoing works are currently under way for
            stage 3, with stage 4 and 5 awaiting council approval.
          </p>
          <p>
            The scope also included; Construction of timber retaining walls,
            Construction of new shared walkways permeable concrete, grass crete,
            Construction of roading, kerbing, streetlights, tree pits, swales,
            Earthworks, cut to fill. Approx. 3000m3 and screening of fill to
            utilise top soils and rock rip rap.
          </p>
        </div>
      </div>
      <div className="col-lg-6 d-flex flex-column align-items-center justify-content-center">
        <StaticImage
          className="mb-3"
          aspectRatio={16 / 9}
          transformOptions={{ cropFocus: "center" }}
          objectPosition="50% 50%"
          src="../../images/brookside.jpg"
          alt="Digger"
        />
        <StaticImage
          aspectRatio={16 / 9}
          transformOptions={{ cropFocus: "center" }}
          objectPosition="50% 50%"
          src="../../images/brookside2.jpg"
          alt="Digger"
        />
      </div>
    </div>
  </Layout>
);

export default BrooksideSubdivision;
